export const layerStyles = {
    dataTable: {
        '--table-cell-padding': 'var(--chakra-space-2)',
        '--narrow-column': '150px',
        '--narrow-column-2': '180px',
        '--narrow-column-kpi': '500px',
        '--button-column': '90px',
        '--small-button-column': '50px',
        '--small-button-column-age': '40px',
        '--small-button-column-delete': '30px',
        '--histories-table-height': '30px',
        '--table-column': '150px',

        table: {
            w: 'full',
            tableLayout: 'fixed',
            borderCollapse: 'separate',
            borderSpacing: '4px',

            thead: {
                bg: 'tryt.primary-1',
            },

            tbody: {
                bg: 'white',
            },

            th: {
                color: 'white',
                fontSize: 'sm',
                p: '4px 24px',
                '&.narrow': {
                    w: 'var(--narrow-column)',
                },
                '&.narrow-2': {
                    w: 'var(--narrow-column-2)',
                },
                '&.narrow-kpi': {
                    w: 'var(--narrow-column-kpi)',
                },
                '&.button': {
                    w: 'var(--button-column)',
                },
                '&.small-button': {
                    w: 'var(--small-button-column)',
                    h: 'var(--histories-table-height)',
                    p: '4px',
                },
                '&.small-button-age': {
                    w: 'var(--small-button-column-age)',
                    h: 'var(--histories-table-height)',
                    p: '4px',
                },
                '&.small-button-delete': {
                    w: 'var(--small-button-column-delete)',
                    h: 'var(--histories-table-height)',
                    p: '4px',
                },
                '&.column-nomal': {
                    w: 'var(--table-column)',
                    h: 'var(--histories-table-height)',
                    pl: '8px',
                    py: '4px',
                },
            },

            td: {
                color: 'black',
                fontSize: 'md',
                p: '4px 24px',
                borderBottom: 'none',
            },

            'th, td': {
                '&.center': {
                    textAlign: 'center',
                },
                '&.right': {
                    textAlign: 'right',
                },
                '&.padding-narrow': {
                    padding: '4px',
                },
                '&.left-only-padding': {
                    px: '8px',
                },
            },
        },
    },
}
