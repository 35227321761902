import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'

import {
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents/index.js'
import { FacilityContext, FacilityItemHStack } from '../index.jsx'

export const KaigoHoursItem = () => {
    const data = useContext(FacilityContext)
    return (
        <TextHeadingComponent
            label="事業所営業時間"
            isObject={[
                data.businessHours.weekday,
                data.businessHours.saturday,
                data.businessHours.sunday,
                data.businessHours.holiday,
                data.regularHoliday,
            ]}
        >
            <Stack>
                <FacilityItemHStack
                    show={[
                        data.businessHours.weekday,
                        data.businessHours.saturday,
                    ]}
                >
                    <TextHStackComponent label="(平日)">
                        {data.businessHours.weekday}
                    </TextHStackComponent>
                    <TextHStackComponent label="(土曜)">
                        {data.businessHours.saturday}
                    </TextHStackComponent>
                </FacilityItemHStack>
                <FacilityItemHStack
                    show={[
                        data.businessHours.sunday,
                        data.businessHours.holiday,
                    ]}
                >
                    <TextHStackComponent label="(日曜)">
                        {data.businessHours.sunday}
                    </TextHStackComponent>
                    <TextHStackComponent label="(祝日)">
                        {data.businessHours.holiday}
                    </TextHStackComponent>
                </FacilityItemHStack>
                <TextHStackComponent label="事業所定休日">
                    {data.regularHoliday}
                </TextHStackComponent>
            </Stack>
        </TextHeadingComponent>
    )
}

export const HoikuHoursItem = () => {
    const data = useContext(FacilityContext)
    return (
        <TextHeadingComponent
            label="事業所営業時間"
            isObject={[
                data.businessHours.weekday,
                data.businessHours.saturday,
                data.businessHours.sunday,
                data.regularHoliday,
            ]}
        >
            <Stack>
                <FacilityItemHStack
                    show={[
                        data.businessHours.weekday,
                        data.businessHours.saturday,
                    ]}
                >
                    <TextHStackComponent label="(平日)">
                        {data.businessHours.weekday}
                    </TextHStackComponent>
                    <TextHStackComponent label="(土曜)">
                        {data.businessHours.saturday}
                    </TextHStackComponent>
                </FacilityItemHStack>
                {/* sundeyでもholidayでもデータの中身が同じなので一つだけ表示 */}
                <TextHStackComponent label="(日曜・祝日)">
                    {data.businessHours.sunday}
                </TextHStackComponent>
                <TextHStackComponent label="事業所定休日">
                    {data.regularHoliday}
                </TextHStackComponent>
            </Stack>
        </TextHeadingComponent>
    )
}
