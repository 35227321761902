import { joi } from '../joi.js'
const {
    string: stringSchema,
    number: numberSchema,
    any: anySchema,
    array: arraySchema,
} = joi.types()
const string = stringSchema.empty([null, ''])
const integer = numberSchema.empty([null, '']).integer()
const entity = joi.object().empty(null).required()
const requiredArray = arraySchema.$.min(1).rule({
    message: '{{#label}}を選択してください',
})
const stringWithLabel = string.alter({ client: (schema) => schema.value() })
export const postCodeSchema = joi
    .string()
    .$.min(7)
    .pattern(/^[0-9]{7}$/)
    .rule({
        message: '{{#label}}を数字7文字で入力してください',
    })
    .label('郵便番号')

export const addressSchema = joi
    .object()
    .unknown(false)
    .keys({
        postCode: postCodeSchema.empty([null, '']),
        prefecture: entity.label('都道府県'),
        city: entity.label('市区町村'),
        street: string.label('町域・番地'),
    })

export const simpleSearchFormSchema = joi.object({
    sfid: string
        .required()
        .valid(joi.ref('$jobseeker.sfId'))
        .label('SFID')
        .messages({
            'any.only': '正しいSFIDを入力してください',
        }),
    requiredTime: joi.object({
        type: entity
            .unknown(true)
            .keys({
                value: string,
            })
            .label('通勤手段'),
        time: integer.max(60).required().label('移動時間'),
        address: joi.when('type.value', {
            is: '4',
            then: anySchema,
            otherwise: addressSchema,
        }),
        station: joi.when('type.value', {
            is: '4',
            then: requiredArray.label('最寄駅'),
            otherwise: anySchema,
        }),
    }),
    serviceCode: arraySchema.label('施設形態'),
    offer: joi.object({
        qualification: arraySchema.label('保有資格'),
        workShift: arraySchema.label('勤務形態'),
        income: joi.object({
            value: integer.label('希望給与'),
            label: entity.label('給与形態'),
        }),
        offerType: stringWithLabel.label('求人の種類'),
    }),
})
