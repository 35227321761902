import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'

import {
    DateFormatComponent,
    TextHStackComponent,
    TextHeadingComponent,
} from '../../FacilityItemComponents/index.js'
import { FacilityContext } from '../index.jsx'

export const FacilityCommonItems = () => {
    const data = useContext(FacilityContext)

    return (
        <TextHeadingComponent
            label="法人住所"
            isObject={[data.corporation.address]}
        >
            <TextHStackComponent>
                {data.corporation.address}
            </TextHStackComponent>
        </TextHeadingComponent>
        // SF情報は src\features\facilities\components\FacilityDetail\Detail.jsx の上部に移動
    )
}

export const FacilityModifiedDateItem = () => {
    const data = useContext(FacilityContext)
    return (
        <TextHeadingComponent label="更新日" isObject={[data.modifiedDate]}>
            <Stack>
                <DateFormatComponent>{data.modifiedDate}</DateFormatComponent>
            </Stack>
        </TextHeadingComponent>
    )
}
