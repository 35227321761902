import { Text, forwardRef, Box } from '@chakra-ui/react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

/** @see {@link https://github.com/chakra-ui/chakra-ui/issues/3658#issuecomment-1250211720} */
export const SectionTitle = forwardRef((props, ref) => {
    return <Text as="h2" textStyle="h2" ref={ref} {...props} />
})

export const NoDataText = forwardRef((props, ref) => {
    return (
        <Text
            fontSize="lg"
            fontWeight="bold"
            color="tryt.gray-5"
            ref={ref}
            {...props}
        />
    )
})

export const DatetimeText = forwardRef(({ children, ...props }, ref) => {
    return (
        <Box as="span" ref={ref} {...props}>
            {format(children, 'yyyy年MM月dd日 HH:mm')}
        </Box>
    )
})
DatetimeText.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date),
    ]).isRequired,
}
