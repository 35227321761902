// see: https://smart-swatch.netlify.app/

const gray = {
    900: '#0D0D0D',
    800: '#212121',
    700: '#35434B',
    600: '#4B5E67',
    500: '#637984',
    400: '#7C929D',
    300: '#90A4AE',
    200: '#BCCED6',
    100: '#ECEFF1',
    50: '#FAFBFB',
}

const primary = {
    900: '#1A455E',
    800: '#226774',
    700: '#2A8984',
    600: '#339E82',
    500: '#3CB37A',
    400: '#59C178',
    300: '#77CF7E',
    200: '#A0DB96',
    100: '#C8E7B5',
    50: '#EAF8F1',
    gradation: 'linear-gradient(123deg, #8bc34a, #3cb37a)',
}

export const colors = {
    // 2024-10-22 今後はこちらの指定は使用禁止
    secondary: gray[800],
    dark: gray[800],
    subBackground: '#f6f5f1',
    lightGray: {
        300: gray[100],
        500: gray[200],
        700: gray[300],
    },
    primary,
    gray,

    // 2024-10-22 色は基本的に下記の中から選ぶようにしてください（eg. tryt.gray-3）
    tryt: {
        'gray-1': gray[50], // 背景色
        'gray-2': gray[100],
        'gray-3': gray[200], // ボーダー
        'gray-4': gray[300], // 薄めの文字色
        'gray-5': gray[800], // 基本の文字色

        'primary-1': primary[500], // メインカラー
        'primary-2': primary[200], // 薄めのメインカラー
        'primary-3': '#006428', // 濃いめのメインカラー

        'accent-1': '#FFB128', // 黄色

        'pale-1': '#F6F5F1', // ベージュ

        'error-1': '#FF0000', // 必須ラベル #E53E3E？

        'haken-1': "#E59EDD", // 派遣のタグとかのカラー

        "blue-1": "#009DDB", //候補者モードの青
    },
}
