import { Box, HStack, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { ExternalLink } from '@/components/Elements'

export const UrlComponent = ({ label, url, children }) => {
    return (
        children && (
            <HStack>
                {label && (
                    <Text
                        fontSize="md"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        color="tryt.gray-4"                    >
                        {label}
                    </Text>
                )}
                <ExternalLink href={url} wordBreak="break-all">
                    {children}
                </ExternalLink>
            </HStack>
        )
    )
}

UrlComponent.propTypes = {
    label: PropTypes.string,
    url: PropTypes.string,
    children: PropTypes.string,
}
