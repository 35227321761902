const toBool = (value) =>
    typeof value === 'string'
        ? ['true', 'false'].some((item) => item === value)
            ? JSON.parse(value)
            : false
        : value

export const REVISION_URL = new URL(
    process.env.REVISION_URL,
    window.location.href
).toString()
export const API_URL = process.env.API_URL
export const GOOGLE_MAPS_API_KEY_CLIENT = process.env.GOOGLE_MAPS_API_KEY_CLIENT
export const MAP_ID = process.env.MAP_ID

export const SF_API_URL = process.env.SF_API_URL
export const SF_CLIENT_ID = process.env.SF_CLIENT_ID
export const SF_CLIENT_SECRET = process.env.SF_CLIENT_SECRET
export const SF_REFRESH_TOKEN = process.env.SF_REFRESH_TOKEN
export const USE_SF_API = toBool(process.env.USE_SF_API)

export const OIDC_AUTHORITY = process.env.OIDC_AUTHORITY
export const OIDC_CLIENT_ID = process.env.OIDC_CLIENT_ID
export const OIDC_CLIENT_SECRET = process.env.OIDC_CLIENT_SECRET
export const OIDC_REDIRECT_URI = new URL(
    process.env.OIDC_REDIRECT_URI,
    window.location.href
).toString()

export const DISABLE_COUNTER = process.env.DISABLE_COUNTER

export const GIT_VERSION = process.env.GIT_VERSION

export const SPEED_FOR_TIME_CALC = process.env.SPEED_FOR_TIME_CALC

export const USE_ATLAS = process.env.USE_ATLAS

export const AREA_MAX = process.env.AREA_MAX

export const ARRAY_LIMIT = process.env.ARRAY_LIMIT

export const SITE = process.env.SITE
export const OCCUPATION = process.env.OCCUPATION

export const NODE_BB_API_TOKEN = process.env.NODE_BB_API_TOKEN

export const PARENT_CATEGORY = process.env.PARENT_CATEGORY

export const NODE_BB_BASE_URL = process.env.NODE_BB_BASE_URL

export const INVISIBILITY_PROPOSAL_HISTORIES = toBool(
    process.env.INVISIBILITY_PROPOSAL_HISTORIES
)

export const IMI_ENRICHMENT_ADDRESS_URL = process.env.IMI_ENRICHMENT_ADDRESS_URL

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY

export const MODE_HAKEN = toBool(process.env.MODE_HAKEN)

// eslint-disable-next-line no-undef
export const WEBPACK_HASH = __webpack_hash__
