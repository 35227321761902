import { Box, HStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'
import site from 'shared/utils/site'

import { SalesTalks } from '../salesTalks.jsx'
import {
    KaigoFacilityItems,
    HoikuFacilityItems,
    KangoFacilityItems,
    EiyoushiFacilityItems,
} from './Items/index.js'

import { ToggleViewingModeStateContext } from '@/components/Layout/index.js'

export const FacilityContext = createContext(undefined)

//看護、POS技師、栄養士、デンタルは同じ
const FacilityItem = site.isHoiku()
    ? HoikuFacilityItems
    : site.isKaigo()
    ? KaigoFacilityItems
    : site.isKango()
    ? KangoFacilityItems
    : site.isPos()
    ? KangoFacilityItems
    : site.isEiyoushi()
    ? EiyoushiFacilityItems
    : site.isDental()
    ? KangoFacilityItems
    : (() => {
          throw new Error('FacilityItem component is not defined.')
      })()

export const FacilityItems = ({ data }) => {
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    if (!data) {
        return null
    }

    return (
        <FacilityContext.Provider value={data}>
            <Box position="relative" width="100%" height="auto">
                {!isJobseekerViewing && (
                    <Box position="absolute" top="0" right="0" zIndex="docked">
                        <SalesTalks />
                    </Box>
                )}
                <Box
                    sx={
                        data.salesTalks?.length > 0 && !isJobseekerViewing
                            ? {
                                  '& > div > div:first-of-type': {
                                      marginRight: '130px',
                                  },
                              }
                            : {}
                    }
                >
                    <FacilityItem />
                </Box>
            </Box>
        </FacilityContext.Provider>
    )
}

FacilityItems.propTypes = {
    isUninitialized: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    isFetching: PropTypes.bool,
    data: PropTypes.any,
}

export const FacilityItemHStack = ({ show = [], children = [] } = {}) => {
    console.log({ children })
    const zip = show
        .map((object, index) => ({
            item: object,
            component: children[index],
        }))
        .filter((pair) => pair.item)

    if (zip.length === 0) {
        return undefined
    }
    if (zip.length === 1) {
        return zip[0].component
    }
    return <HStack>{zip.map(({ component }) => component)}</HStack>
}

FacilityItemHStack.propTypes = {
    show: PropTypes.array,
    children: PropTypes.array,
}
