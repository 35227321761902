import {
    Box,
    Button,
    CloseButton,
    HStack,
    useDisclosure,
    Heading,
    Spacer,
    Stack,
} from '@chakra-ui/react'
import { Rnd } from 'react-rnd'
import parse from 'html-react-parser'

import { useContext, useState } from 'react'
import { Select } from 'chakra-react-select'
import { FacilityContext } from './FacilityItems/index.jsx'

export const SalesTalks = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const data = useContext(FacilityContext)

    // TODO: セールストークのデータ整備がされたらlabelをセールストークのタイトルなどに変更
    const options = data?.salesTalks?.map((_, index) => ({
        label: `セールストーク${index + 1}`,
        value: index,
    }))

    const close = () => {
        setSelectedIndex(0)
        onClose()
    }

    const handleMouseDown = (event) => {
        event.stopPropagation()
    }

    const chakraStyles = {
        container: (provided) => ({
            ...provided,
            cursor: 'pointer',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: state.isSelected ? 'default' : 'pointer',
        }),
    }

    if (data?.salesTalks?.length) {
        return (
            <>
                <Button
                    onClick={() => {
                        isOpen ? close() : onOpen()
                    }}
                >
                    セールストーク
                </Button>
                {isOpen && (
                    <Rnd
                        default={{
                            x: -267,
                            y: 50,
                            width: 400,
                            height: 400,
                        }}
                        style={{
                            boxShadow: 'rgb(144 164 174 / 25%) 0px 0px 8px 0px',
                            borderRadius: '10px',
                            background: '#ffffff',
                            position: 'fixed',
                            zIndex: 1000,
                            overflow: 'visible',
                        }}
                    >
                        <HStack bg="tryt.pale-1" p="16px 12px">
                            <Heading size="md" color="tryt.primary-1">
                                セールストーク
                            </Heading>
                            <Spacer />
                            <CloseButton onClick={close} />
                        </HStack>

                        <Box onMouseDown={handleMouseDown} m="8px 12px">
                            <Select
                                options={options}
                                chakraStyles={chakraStyles}
                                onChange={(selectedOption) =>
                                    setSelectedIndex(selectedOption?.value)
                                }
                                defaultValue={options[0]}
                            />
                        </Box>
                        <Box
                            overflowY="scroll"
                            overflowX="hidden"
                            h="calc(100% - 114px)"
                        >
                            {selectedIndex !== null && (
                                <Box fontWeight="bold" padding="20px 30px">
                                    <Stack fontSize="16px">
                                        <Box>
                                            {parse(
                                                data.salesTalks[selectedIndex]
                                                    ?.salesTalkPoint
                                            )}
                                        </Box>
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                    </Rnd>
                )}
            </>
        )
    }
    return null
}
