export const formLabelTheme = {
    baseStyle: {
        fontWeight: 'bold',
        color: 'tryt.gray-5',
        fontFamily: 'fonts',
        _groupDisabled: {
            opacity: 1,
        },
    },
}
