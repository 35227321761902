import {
    Button,
    HStack,
    VStack,
    Text,
    Switch,
    FormLabel,
    Spacer,
    ModalFooter,
} from '@chakra-ui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useEffect } from 'react'

import {
    useSearchEkitanStationsQuery,
    useUpdateStationApiCodeMutation,
    useGetReverseGeocodeQuery,
} from '../api'
import { SearchForm } from './SearchForm'
import { StationList } from './StationList'
import { StationName } from './StationName'

import { getUserId } from '@/features/auth'

export const LinkStationForm = ({ station, isActive, onLink, onDeactive }) => {
    const [inputValue, setInputValue] = useState(station.name)
    const [searchQuery, setSearchQuery] = useState(inputValue)
    const [isAbandonedStation, setIsAbandonedStation] = useState(false)

    let isDeleted = false
    if (station.deletedAt) {
        isDeleted = true
    }

    useEffect(() => {
        setIsAbandonedStation(isDeleted)
    }, [])

    const stationType = station.gmId === undefined ? 1 : 2 // TODO
    const { data } = useSearchEkitanStationsQuery(
        isActive && searchQuery.length !== 0
            ? {
                  type: stationType,
                  stationId: station._id,
                  name: searchQuery,
              }
            : skipToken
    )
    let stationList = data?.items
    if (data && station.apiCode) {
        const selected = {
            isRailwayStation: stationType === 1 ? true : false,
            apiCode: station.apiCode,
            name: station.ekitan.name,
            lines: station.ekitan.lines,
        }

        const items = [selected, ...data.items]
        stationList = items.filter((item, index, self) => {
            const nameList = self.map((item) => item['name'])
            if (nameList.indexOf(item.name) === index) {
                return item
            }
        })
    }
    const { data: revGeocodeData } = useGetReverseGeocodeQuery({
        lat: station.location.coordinates.flat()[1],
        lon: station.location.coordinates.flat()[0],
    })
    const address = revGeocodeData?.address

    const [updateStationApiCode] = useUpdateStationApiCodeMutation()
    const [selectedIndex, setSelectedIndex] = useState()

    const onChange = useCallback(
        (e) => setInputValue(e.target.value),
        [setInputValue]
    )
    const onSearch = useCallback(() => {
        setSearchQuery(inputValue)
    }, [inputValue, setSearchQuery])
    const onSelect = useCallback(
        (index) => setSelectedIndex(index),
        [setSelectedIndex]
    )
    const onClick = useCallback(async () => {
        const datetime = new Date()
        const userId = getUserId()
        // @ts-ignore
        const selectedStation = stationList[selectedIndex]
        const apiCodeUpdatedStation = {
            ...station,
            apiCode: selectedStation ? selectedStation.apiCode : '',
            ekitan: selectedStation
                ? {
                      code: selectedStation.apiCode,
                      name: selectedStation.name,
                      lines: selectedStation.lines,
                      company: selectedStation.company,
                  }
                : '',
            updatedAt: datetime,
            updatedBy: userId,
        }
        if (isAbandonedStation && !isDeleted) {
            apiCodeUpdatedStation.deletedAt = datetime
            apiCodeUpdatedStation.deletedBy = userId
        } else if (!isAbandonedStation && isDeleted) {
            apiCodeUpdatedStation.deletedAt = ''
            apiCodeUpdatedStation.deletedBy = ''
        }
        if (station?.ekitan?.name !== selectedStation?.name) {
            apiCodeUpdatedStation.linkedAt = datetime
            apiCodeUpdatedStation.linkedBy = userId
        }
        await updateStationApiCode(apiCodeUpdatedStation).unwrap()
        onLink && onLink(selectedStation)
        onDeactive && onDeactive()
    }, [
        station,
        isAbandonedStation,
        isDeleted,
        data,
        selectedIndex,
        updateStationApiCode,
        onLink,
        onDeactive,
    ])

    const switching = (e) => {
        setIsAbandonedStation(e.target.checked)
    }

    return (
        <>
            <VStack>
                <div>
                    <StationName
                        station={station}
                        isAbandonedStation={isAbandonedStation}
                    />
                    <HStack>
                        {address ? <Text>住所: {address}</Text> : ''}
                        <Spacer />
                        <Switch
                            id="abandoned-station"
                            name="abandoned-station"
                            onChange={switching}
                            isChecked={isAbandonedStation}
                            sx={{
                                'span.chakra-switch__track': {
                                    boxShadow: 'none',
                                },
                                'span.chakra-switch__track[data-checked]': {
                                    background: 'tryt.primary-1',
                                },
                            }}
                        />
                        <FormLabel
                            fontSize="14px"
                            color="tryt.primary-1"
                            borderBottom="tryt.primary-1"
                            fontWeight="bolder"
                            htmlFor="abandoned-station"
                            cursor="pointer"
                        >
                            廃駅
                        </FormLabel>
                    </HStack>
                </div>
                <SearchForm
                    value={inputValue}
                    onChange={onChange}
                    onClick={onSearch}
                />
                <StationList
                    stations={stationList ?? []}
                    onSelect={onSelect}
                    station={station}
                />
            </VStack>
            <ModalFooter>
                <Button onClick={onDeactive} w="100px">
                    キャンセル
                </Button>
                <Button
                    onClick={onClick}
                    marginLeft="8px"
                    w="100px"
                    backgroundColor="tryt.primary-1"
                    color="white"
                    _hover={{
                        opacity: '0.6',
                        filter: 'brightness(110%)',
                    }}
                >
                    決定
                </Button>
            </ModalFooter>
        </>
    )
}

LinkStationForm.propTypes = {
    station: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    onLink: PropTypes.func,
    onDeactive: PropTypes.func,
}
