import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'
import {
    HoikuServiceCode,
    KaigoServiceCode,
    KangoServiceCode,
} from 'shared/utils/serviceCode'

import { FacilityContext } from '../index.jsx'

import {
    HoikuFacilityItems,
    KaigoFacilityItems,
    KangoFacilityItems,
    KangoOfficeItem,
    EiyoushiItems,
    FacilityModifiedDateItem,
} from './index.js'

import { ToggleViewingModeStateContext } from '@/components/Layout/index.js'

export const EiyoushiFacilityItems = () => {
    const data = useContext(FacilityContext)

    if (KaigoServiceCode(data.serviceCode)) {
        return <KaigoFacilityItems />
    }
    if (HoikuServiceCode(data.serviceCode)) {
        return <HoikuFacilityItems />
    }
    if (KangoServiceCode(data.serviceCode)) {
        return <KangoFacilityItems />
    } else
        return (
            <>
                <Stack>
                    {/* Corporation */}
                    <KangoOfficeItem />
                    <EiyoushiItems />
                    <FacilityModifiedDateItem />
                </Stack>
            </>
        )
}
