// see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
export const buttonTheme = {
    baseStyle: {
        fontFamily: 'fonts',
    },
    variants: {
        outline: {
            rounded: 'full',
            color: 'tryt.primary-1',
            bg: 'white',
            boxShadow: 'sm',
            _active: {
                bg: 'tryt.primary-1',
                color: 'white',
                boxShadow: 'none',
                _disabled: {
                    bg: 'tryt.primary-1',
                },
            },
            _disabled: {
                opacity: 1,
                cursor: 'not-allowed',
                boxShadow: 'none',
            },
        },
        gradient: {
            rounded: 'full',
            color: 'white',
            fontFamily: 'fonts',
            px: '12',
            py: '6',
            backgroundImage: 'linear-gradient(45deg, #8BC34A 0%, #3CB37A 100%)',
            boxShadow: 'sm',
            _hover: {
                backgroundImage:
                    'linear-gradient(45deg, #3CB37A 0%, #8BC34A 100%)',
            },
        },
        gradientY: {
            rounded: 'full',
            color: 'white',
            bg: 'linear-gradient(#FFCD00,#FF9500)',
            _hover: {
                opacity: '0.6',
                bg: 'linear-gradient(#FFCD00,#FF9500)',
            },
            _active: {
                bg: 'linear-gradient(#FFCD00,#FF9500)',
            },
            h: '36px',
            w: '100px',
        },
    },
    sizes: {
        md: {
            fontSize: 'sm',
        },
    },
    defaultProps: {
        variant: 'outline',
        colorScheme: 'primary',
    },
}
