import { Box } from '@chakra-ui/react'
import { useContext } from 'react'

import {
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents/index.js'
import { FacilityContext } from '../index.jsx'

export const KaigoEmployeesItem = () => {
    const data = useContext(FacilityContext)
    const kaigoEmployees = [
        { label: '事業者総従業員数', value: data.officeEmployeeCount },
        {
            label: '介護経験5年以上職員比率',
            value: data.nursingExperienceRatio.over5y,
        },
        {
            label: '介護経験10年以上職員比率',
            value: data.nursingExperienceRatio.over10y,
        },
        { label: '夜勤平均スタッフ人数', value: data.nightShiftStaffCountAvg },
        { label: '夜勤最小スタッフ人数', value: data.nightShiftStaffCountMin },
        {
            label: '従業員男性比率',
            value: data.employeeInfoMaleRatio,
            endStr: '%',
        },
        {
            label: '従業員女性比率',
            value: data.employeeInfoFemaleRatio,
            endStr: '%',
        },
    ]
    return (
        <TextHeadingComponent
            label="従業員"
            isObject={[
                data.officeEmployeeCount,
                data.nursingExperienceRatio.over5y,
                data.nursingExperienceRatio.over10y,
                data.nightShiftStaffCountMin,
                data.nightShiftStaffCountAvg,
                data.employeeInfoMaleRatio,
                data.employeeInfoFemaleRatio,
            ]}
        >
            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="8px">
                {kaigoEmployees.map((item, index) => {
                    if (item.value) {
                        return (
                            <Box key={index}>
                                <TextHStackComponent
                                    label={item.label}
                                    endStr={item.endStr}
                                >
                                    {item.value}
                                </TextHStackComponent>
                            </Box>
                        )
                    }
                    return null
                })}
            </Box>
        </TextHeadingComponent>
    )
}

export const HoikuEmployeesItem = () => {
    const data = useContext(FacilityContext)
    //TODO: 他のとこに置きたい
    const hoikuEmployees = [
        { label: '合計_常勤', value: data.totalNumberOfWorkersFullTime },
        { label: '合計_非常勤', value: data.totalNumberOfWorkersPartTime },
        {
            label: '保育教諭_常勤',
            value: data.numberOfNurseryschoolTeachersFullTime,
        },
        {
            label: '保育教諭_非常勤',
            value: data.numberOfNurseryschoolTeachersPartTime,
        },
        {
            label: '教諭又は保育士_常勤',
            value: data.numberOfTeachersOrChildcareWorkersFullTime,
        },
        {
            label: '教諭又は保育士_非常勤',
            value: data.numberOfTeachersOrChildcareWorkersPartTime,
        },
        { label: '保育士_常勤', value: data.numberOfChildcareWorkersFullTime },
        {
            label: '保育士_非常勤',
            value: data.numberOfChildcareWorkersPartTime,
        },
        {
            label: '保育従事者_常勤',
            value: data.numberOfChildcareGiversFullTime,
        },
        {
            label: '保育従事者_非常勤',
            value: data.numberOfChildcareGiversPartTime,
        },
        { label: '教諭_常勤', value: data.numberOfTeachersFullTime },
        { label: '教諭_非常勤', value: data.numberOfTeachersPartTime },
        { label: '看護師_常勤', value: data.numberOfNursesFullTime },
        { label: '看護師_非常勤', value: data.numberOfNursesPartTime },
    ]
    return (
        <>
            <TextHeadingComponent
                label="従業員"
                isObject={[
                    data.totalNumberOfWorkersFullTime,
                    data.totalNumberOfWorkersPartTime,
                    data.numberOfNurseryschoolTeachersFullTime,
                    data.numberOfNurseryschoolTeachersPartTime,
                    data.numberOfTeachersOrChildcareWorkersFullTime,
                    data.numberOfTeachersOrChildcareWorkersPartTime,
                    data.numberOfChildcareWorkersFullTime,
                    data.numberOfChildcareWorkersPartTime,
                    data.numberOfChildcareGiversFullTime,
                    data.numberOfChildcareGiversPartTime,
                    data.numberOfTeachersFullTime,
                    data.numberOfTeachersPartTime,
                    data.numberOfNursesFullTime,
                    data.numberOfNursesPartTime,
                ]}
            >
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(3, 1fr)"
                    gap="8px"
                >
                    {hoikuEmployees.map((item, index) => {
                        if (item.value) {
                            return (
                                <Box key={index}>
                                    <TextHStackComponent label={item.label}>
                                        {item.value}
                                    </TextHStackComponent>
                                </Box>
                            )
                        }
                        return null
                    })}
                </Box>
            </TextHeadingComponent>
            <TextHeadingComponent
                label={`職員一人当たりの\n子どもの数`}
                isObject={[data.numberOfChildrenPerStaff]}
            >
                <TextHStackComponent label="">
                    {data.numberOfChildrenPerStaff}
                </TextHStackComponent>
            </TextHeadingComponent>
        </>
    )
}
