import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'

import {
    UrlComponent,
    DateFormatComponent,
    TextHeadingComponent,
    TextHStackComponent,
    InlineLabelComponent,
} from '../../FacilityItemComponents/index.js'
import { FacilityContext, FacilityItemHStack } from '../index.jsx'

import { ToggleViewingModeStateContext } from '@/components/Layout/index.js'

export const KaigoOfficeItem = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    const OfficeDetail = isJobseekerViewing ? (
        <DateFormatComponent label="事業開始年月日">
            {data.officeStartDate}
        </DateFormatComponent>
    ) : (
        <FacilityItemHStack show={[data.officeCode, data.officeStartDate]}>
            <TextHStackComponent label="事業者番号">
                {data.officeCode}
            </TextHStackComponent>
            <DateFormatComponent label="事業開始年月日">
                {data.officeStartDate}
            </DateFormatComponent>
        </FacilityItemHStack>
    )

    return (
        <TextHeadingComponent
            label="法人基本情報"
            isObject={[
                data.corporation.name,
                // 候補者説明モード切替時に介サURL、事業者番号しかデータが無い場合、
                // コンポーネントを非表示のみだと大項目だけ出てくるのでデータもnullにする
                !isJobseekerViewing ? data.officeCode : null,
                data.officeStartDate,
                data.otherServiceAttached,
                data.officeDirection,
            ]}
        >
            <Stack>
                <TextHStackComponent label="運営法人名">
                    {data.corporation.name}
                </TextHStackComponent>
                {OfficeDetail}
                <InlineLabelComponent label="併設サービス">
                    {data.otherServiceAttached}
                </InlineLabelComponent>
                <InlineLabelComponent label="運営方針">
                    {data.officeDirection}
                </InlineLabelComponent>
            </Stack>
        </TextHeadingComponent>
    )
}

export const HoikuOfficeItem = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    const OfficeDetail = isJobseekerViewing ? (
        <DateFormatComponent label="事業開始年月日">
            {data.officeStartDate}
        </DateFormatComponent>
    ) : (
        <FacilityItemHStack
            show={[data.wamUrl, data.officeCode, data.officeStartDate]}
        >
            <UrlComponent label="WamURL" url={data.wamUrl}>
                {data.wamUrl}
            </UrlComponent>
            <TextHStackComponent label="事業者番号">
                {data.officeCode}
            </TextHStackComponent>
            <DateFormatComponent label="事業開始年月日">
                {data.officeStartDate}
            </DateFormatComponent>
        </FacilityItemHStack>
    )

    return (
        <TextHeadingComponent
            label="法人基本情報"
            isObject={[
                data.corporation.name,
                // 候補者説明モード切替時にWamURL、事業者番号しかデータが無い場合、
                // コンポーネントを非表示のみだと大項目だけ出てくるのでデータもnullにする
                !isJobseekerViewing ? data.wamUrl : null,
                !isJobseekerViewing ? data.officeCode : null,
                data.officeStartDate,
                data.officeDirection,
            ]}
        >
            <Stack>
                <TextHStackComponent label="運営法人名">
                    {data.corporation.name}
                </TextHStackComponent>
                {OfficeDetail}
                <InlineLabelComponent label="運営方針">
                    {data.officeDirection}
                </InlineLabelComponent>
            </Stack>
        </TextHeadingComponent>
    )
}

export const KangoOfficeItem = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    const OfficeDetail = isJobseekerViewing ? (
        <DateFormatComponent label="事業開始年月日">
            {data.officeStartDate}
        </DateFormatComponent>
    ) : (
        <FacilityItemHStack show={[data.officeCode, data.officeStartDate]}>
            <TextHStackComponent label="事業者番号">
                {data.officeCode}
            </TextHStackComponent>
            <DateFormatComponent label="事業開始年月日">
                {data.officeStartDate}
            </DateFormatComponent>
        </FacilityItemHStack>
    )

    return (
        <>
            <TextHeadingComponent
                label="法人基本情報"
                isObject={[
                    data.corporation.name,
                    // 候補者説明モード切替時にscuelURL、事業者番号しかデータが無い場合、
                    // コンポーネントを非表示のみだと大項目だけ出てくるのでデータもnullにする
                    !isJobseekerViewing ? data.officeCode : null,
                    data.officeStartDate,
                ]}
            >
                <Stack>
                    <TextHStackComponent label="運営法人名">
                        {data.corporation.name}
                    </TextHStackComponent>
                    {OfficeDetail}
                </Stack>
            </TextHeadingComponent>
        </>
    )
}
