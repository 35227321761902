import { Tabs, TabList, Tab, forwardRef } from '@chakra-ui/react'

export const RoundedTabs = forwardRef(
    /**
     * @param {import('@chakra-ui/react').TabsProps} props
     * @returns
     */
    (props, ref) => {
        return <Tabs ref={ref} variant="soft-rounded" isFitted {...props} />
    }
)

export const RoundedTabList = forwardRef(
    /**
     * @param {import('@chakra-ui/react').TabListProps} props
     * @returns
     */
    (props, ref) => {
        return <TabList ref={ref} bg="white" rounded="full" p={1} {...props} />
    }
)

export const RoundedTab = forwardRef(
    /**
     * @param {import('@chakra-ui/react').TabProps} props
     * @returns
     */
    (props, ref) => {
        return (
            <Tab
                ref={ref}
                rounded="full"
                bg="white"
                color="tryt.gray-5" //UI改善の時カラー注意(黒になっていいのか？)
                _selected={{
                    bg: 'primary.50',
                    color: 'tryt.primary-1',
                }}
                {...props}
            />
        )
    }
)
