import {
    Box,
    Checkbox,
    Heading,
    HStack,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react'
import { useState } from 'react'

import { useListHistoriesQuery } from '../api/index'
import { SearchTimes, SearchCriteria } from './SearchTimesTableCell'

import { Table, TableContainer, Pagination } from '@/components/Elements'

const searchTimesColumns = [
    {
        accessor: 'SearchTimes',
        Header: '検索回数',
        Cell: SearchTimes,
    },
    {
        accessor: 'SearchCriteria',
        Header: '検索条件',
        Cell: SearchCriteria,
    },
]

export const SearchTimesTable = () => {
    const [searchTimesCurrentPage, setSearchTimesCurrentPage] = useState(1)
    const [isOnlySavedHistories, setIsOnlySavedHistories] = useState(false)

    const onChange = (e) => {
        setIsOnlySavedHistories(e.target.checked)
        setSearchTimesCurrentPage(1)
    }

    const {
        // currentData: searchTimesCurrentData,
        data: searchTimesData,
        isSuccess: searchTimesIsSuccess,
    } = useListHistoriesQuery({
        page: searchTimesCurrentPage,
        limit: 20,
        pageType: 'searchTimes',
    })

    const {
        // currentData: onlySavedCurrentData,
        data: onlySavedData,
        isSuccess: onlySavedIsSuccess,
    } = useListHistoriesQuery({
        page: searchTimesCurrentPage,
        limit: 20,
        pageType: 'searchTimes',
        onlySaved: true,
    })

    if (
        !searchTimesIsSuccess ||
        (isOnlySavedHistories && !onlySavedIsSuccess)
    ) {
        return <div />
    }

    return (
        <Box>
            <Stack
                bg="primary.50"
                maxWidth="1000px"
                w="100%"
                p="35px 25px"
                margin="25px auto 20px auto"
            >
                <HStack>
                    <Heading
                        as="h5"
                        fontSize="20px"
                        color="tryt.primary-1"
                        fontWeight="bolder"
                    >
                        検索回数（管理者専用）
                    </Heading>
                    <Spacer />
                    <Checkbox
                        isChecked={isOnlySavedHistories}
                        onChange={onChange}
                        style={{ borderColor: '#90A4AE' }}
                    >
                        <Text>保存された検索条件のみ表示</Text>
                    </Checkbox>
                </HStack>
                <TableContainer
                    sx={{
                        Thead: {
                            bg: 'tryt.primary-1',
                        },
                        Th: {
                            color: '#FFFFFF',
                            fontSize: '14px',
                            borderColor: 'primary.50',
                            borderRightWidth: '4px',
                            pt: '4px',
                            pb: '4px',
                            whiteSpace: 'noWrap',
                        },
                        Tbody: {
                            bg: 'white',
                        },
                        Td: {
                            borderColor: 'primary.50',
                            borderRightWidth: '4px',
                            borderTopWidth: '4px',
                            borderBottomWidth: '4px',
                            pt: '4px',
                            pb: '4px',
                            maxW: '100%',
                        },
                    }}
                >
                    <Table
                        columns={searchTimesColumns}
                        data={
                            isOnlySavedHistories
                                ? onlySavedData.items
                                : searchTimesData.items
                        }
                    />
                </TableContainer>
            </Stack>
            <Pagination
                totalCount={
                    isOnlySavedHistories
                        ? onlySavedData.paging.pageCount
                        : searchTimesData.paging.pageCount
                }
                currentPage={
                    isOnlySavedHistories
                        ? onlySavedData.paging.page
                        : searchTimesData.paging.page
                }
                handlePageChanged={setSearchTimesCurrentPage}
            />
        </Box>
    )
}

SearchTimesTable.propTypes = {}
