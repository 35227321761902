import { Stack } from '@chakra-ui/react'

import {
    HoikuEmployeesItem,
    HoikuHoursItem,
    HoikuItems,
    HoikuOfficeItem,
    HoikuUsersItem,
    FacilityModifiedDateItem,
} from './index.js'

export const HoikuFacilityItems = () => {
    return (
        <Stack spacing="5px">
            <HoikuOfficeItem />
            <HoikuHoursItem />
            <HoikuEmployeesItem />
            <HoikuUsersItem />
            <HoikuItems />
            <FacilityModifiedDateItem />
        </Stack>
    )
}
