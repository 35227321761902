import {
    Box,
    Button,
    Icon,
    Input,
    Text,
    HStack,
    Spacer,
    Checkbox,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Stack,
    FormControl,
    FormLabel,
    VisuallyHidden,
} from '@chakra-ui/react'
import { joiResolver } from '@hookform/resolvers/joi'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsFillStarFill } from 'react-icons/bs'
import { joi } from 'shared'
import generateUserId from 'shared/utils/auth'

import { highPerformerApi, userApi } from '../..'
import {
    SetIdContext,
    OfficeContext,
    ToDisplayNameContext,
} from './HighPerformersContext'

import { Table, TableContainer, Pagination } from '@/components/Elements'

const charLength = (value, helpers) => {
    // search term must be longer than one character 対策
    const bytes = encodeURIComponent(value).replace(/%../g, 'x').length
    // console.log('bytes', bytes)
    if (bytes === 1) {
        return helpers.error('string.min')
    }
    return value
}

const schema = joi.object({
    q: joi
        .string()
        .trim()
        .custom(charLength)
        .when('office', {
            is: false,
            // then: joi.required(),
            otherwise: joi.allow(''),
        })
        .messages({ '*': '入力してください', 'string.min': '短すぎます' }),
    office: joi.any(),
})

export const ModalForm = ({ id }) => {
    const setId = useContext(SetIdContext)
    const { item } =
        highPerformerApi.endpoints.listHighPerformers.useQueryState(undefined, {
            skip: !id,
            selectFromResult: ({ data }) => {
                return {
                    item: data?.items?.find((item) => item._id === id),
                }
            },
        })
    const onClose = useCallback(() => {
        setId(null)
    }, [setId])
    // console.log(item, data)
    if (item) {
        return (
            <OfficeContext.Provider value={item}>
                <Modal isOpen onClose={onClose}>
                    <ModalOverlay />
                    <SearchForm />
                </Modal>
            </OfficeContext.Provider>
        )
    } else {
        return null
    }
}
ModalForm.propTypes = {
    id: PropTypes.string,
}

const modalColumns = [
    {
        Header: 'ユーザーID',
        id: 'userId',
        accessor: (row) => {
            return row.Email ? generateUserId(row.Email) : ''
        },
    },
    {
        Header: '営業所',
        accessor: 'SalesOfficeName__c',
        Cell: Office,
    },
    {
        Header: '部署',
        accessor: 'Department',
    },
    {
        Header: '役職',
        accessor: 'Title',
    },
    {
        Header: 'ロール',
        accessor: 'RollName__c',
    },
    {
        Header: '',
        id: 'add',
        headerClassName: 'button',
        cellClassName: 'center',
        Cell: AddButton,
    },
]

const SearchForm = () => {
    const item = useContext(OfficeContext)
    const {
        handleSubmit,
        register,
        formState: { errors, submitCount },
        setFocus,
        // watch,
    } = useForm({
        defaultValues: {
            q: '',
            office: item.name,
        },
        resolver: joiResolver(schema),
    })
    // console.log(errors, watch())
    const [query, setQuery] = useState(null)
    const onSubmit = useCallback(
        (data) => {
            setQuery(data)
        },
        [setQuery]
    )
    useEffect(() => {
        // console.log('submitCount', submitCount)
        setFocus('q')
    }, [setFocus, submitCount])
    useEffect(() => {
        // console.log('onSubmit')
        handleSubmit(onSubmit)()
    }, [onSubmit, handleSubmit])
    return (
        <ModalContent minW="1200px" p="40px">
            <ModalCloseButton />
            <ModalBody bg="primary.50" p={6}>
                <Stack
                    spacing={4}
                    sx={{
                        '.pagination-container': {
                            mx: '-24px',
                            mb: '-24px',
                            py: 4,
                        },
                    }}
                >
                    <Text as="h5" textStyle="h2">
                        {item.dispName}
                        <Box as="span" fontSize="md" color="black">
                            のAI評価者を選出
                        </Box>
                    </Text>
                    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                        {errors?.q?.message && (
                            <Text color="tryt.error-1" mb="2">
                                {errors.q.message}
                            </Text>
                        )}
                        <HStack spacing={4}>
                            <FormControl w="300px" isInvalid={!!errors?.q}>
                                <VisuallyHidden>
                                    <FormLabel>キーワード</FormLabel>
                                </VisuallyHidden>
                                <Input
                                    placeholder="氏名かメールアドレスを入力してください"
                                    {...register('q')}
                                />
                            </FormControl>
                            <Checkbox value={item.name} {...register('office')}>
                                営業所に所属
                            </Checkbox>
                            <Spacer />
                            <Button type="submit">上位200件を検索</Button>
                        </HStack>
                    </Box>

                    <UserList query={query} />
                </Stack>
            </ModalBody>
        </ModalContent>
    )
}
SearchForm.propTypes = {}

const UserList = ({ query }) => {
    const [trigger, { isUninitialized, isFetching, data }] =
        userApi.useLazySearchUsersQuery()
    const [{ currentPage, totalCount, isLoaded, subset }, setState] = useState({
        currentPage: 0,
        totalCount: 0,
        subset: [],
        isLoaded: false,
    })
    const setPage = useCallback(
        (index) => {
            setState((prev) => ({
                ...prev,
                currentPage: index,
                subset: data[index - 1],
            }))
        },
        [setState, data]
    )
    useEffect(() => {
        if (query) {
            trigger(query, false)
        }
    }, [query])
    useEffect(() => {
        if (Array.isArray(data)) {
            setState({
                currentPage: 1,
                totalCount: data.length,
                subset: data?.[0] ?? [],
                isLoaded: true,
            })
        }
    }, [data])
    if (isUninitialized) {
        return <Text>ここに検索結果が表示されます</Text>
    } else if (isFetching || !isLoaded) {
        return <Text>Loading...</Text>
    } else if (!totalCount) {
        return <Text>ユーザーが存在しません</Text>
    }
    const inactiveRows = subset.map((item, index) => item.IsActive === false ? index + 1 : null).filter(i => i !== null);
    return (
        <>
            <TableContainer
                layerStyle="dataTable"
                sx={{
                     ...inactiveRows.reduce((acc, rowIndex) => ({
                     ...acc,
                    [`tbody tr:nth-of-type(${rowIndex})`]: {
                    backgroundColor: 'tryt.gray-2',
                    }
                    }), {}),
                    // ユーザーID, 部署
                    'th:nth-of-type(1),th:nth-of-type(3)': {
                        width: '180px',
                    },
                    // 役職
                    'th:nth-of-type(4)': {
                        width: '150px',
                    },
                }}
            >
                 <Table data={subset} columns={modalColumns} />
            </TableContainer>
            <Box bg="white" className="pagination-container">
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    handlePageChanged={setPage}
                />
            </Box>
        </>
    )
}
UserList.propTypes = {
    query: PropTypes.any,
}

function AddButton(props) {
    const {
        row: {
            values: { userId },
        },
    } = props
    const office = useContext(OfficeContext)
    const [putHighPerformer, { isLoading }] =
        highPerformerApi.endpoints.putHighPerformer.useMutation()
    // console.log(props, office, userId)
    if (office.hp.includes(userId)) {
        return <Icon as={BsFillStarFill} color="yellow.300" />
    }
    return (
        <Button
            variant="link"
            isLoading={isLoading}
            onClick={() => {
                putHighPerformer({ _id: office._id, userId })
            }}
        >
            決定
        </Button>
    )
}
AddButton.propTypes = {
    row: PropTypes.shape({
        values: PropTypes.shape({
            userId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
}

function Office(props) {
    const { value: officeName } = props
    const toDisplayName = useContext(ToDisplayNameContext)
    const displayName = toDisplayName(officeName)
    // console.log({
    //     displayName,
    //     officeName,
    // })
    if (displayName) {
        return <Box as="b">{displayName}</Box>
    }
    return <>{officeName}</>
}
Office.propTypes = {
    value: PropTypes.string,
}
