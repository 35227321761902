import site from 'shared/utils/site'

const hoikuCommonServiceTypeChoices = [
    {
        label: '保育園',
        iconType: 'HoikuenIcon',
        options: [
            { label: '認可保育園', value: '401', short: '認可' },
            { label: '小規模保育園', value: '402', short: '小規模' },
            { label: '認可外保育園', value: '405', short: '認可外' },
            { label: 'ベビーホテル', value: '412', short: 'BH' },
        ],
    },
    {
        label: '幼稚園',
        iconType: 'YouchienIcon',
        options: [
            { label: '幼稚園‐公立', value: '406', short: '公立' },
            { label: '幼稚園‐私立', value: '407', short: '私立' },
        ],
    },
    {
        label: 'こども園',
        iconType: 'KodomoenIcon',
        options: [
            { label: '認定こども園－保育所型', value: '409', short: '保育型' },
            { label: '認定こども園－幼稚園型', value: '410', short: '幼稚型' },
            {
                label: '認定こども園－幼保連携型',
                value: '411',
                short: '幼保型',
            },
            {
                label: '認定こども園－地方裁量型',
                value: '408',
                short: '地方型',
            },
        ],
    },
    {
        label: '認証・認定保育園',
        iconType: 'HoikuenIcon',
        options: [{ label: '認証・認定保育園', value: '403', short: '認証保' }],
    },
    {
        label: '院内・企業内保育園',
        iconType: 'HoikuenIcon',
        options: [
            { label: '院内・企業内保育園', value: '404', short: '院・企' },
        ],
    },
    {
        label: '居宅',
        iconType: 'HoumonIcon',
        options: [
            {
                label: 'ベビーシッター（居宅訪問型保育）',
                value: '413',
                short: 'シッタ',
            },
            { label: '居宅訪問型児童発達支援', value: '223', short: '訪児発' },
        ],
    },
    {
        label: '障害者施設',
        iconType: 'KurumaisuIcon',
        options: [
            { label: '児童発達支援', value: '220', short: '児発支' },
            { label: '医療型児童発達支援', value: '221', short: '医児発' },
            { label: '放課後デイ', value: '222', short: '放デイ' },
            { label: '障害児入所', value: '225', short: '児入所' },
            { label: '障害児相談支援', value: '226', short: '障相談' },
        ],
    },
]
const kaigoCommonServiceTypeChoices = [
    {
        label: '訪問',
        iconType: 'HoumonIcon',
        options: [
            { label: '訪問介護', value: '101', short: '訪介護' },
            { label: '夜間対応型訪問介護', value: '102', short: '夜訪介' },
            { label: '訪問リハ', value: '105', short: '訪リハ' },
            { label: '訪問入浴', value: '103', short: '訪入浴' },
            { label: '定期巡回', value: '121', short: '定巡回' },
            { label: '訪問看護', value: '104', short: '訪看護' },
        ],
    },
    {
        label: '通所',
        iconType: 'KoureishaIcon',
        options: [
            { label: 'デイ', value: '106', short: 'デイ' },
            { label: 'デイケア', value: '107', short: 'デイケア' },
        ],
    },
    {
        label: '入所',
        iconType: 'KoureishaIcon',
        options: [
            { label: 'ショートステイ', value: '108', short: 'SS' },
            { label: 'GH', value: '110', short: 'GH' },
            { label: '特養', value: '115', short: '特養' },
            { label: '地域密着特養', value: '116', short: '地特' },
            { label: '介護付き有老', value: '111', short: '介有老' },
            { label: '住宅型有老', value: '112', short: '住有老' },
            { label: '老健', value: '109', short: '老健' },
            { label: 'サ高住', value: '113', short: 'サ高住' },
            { label: '小多機', value: '117', short: '小多機' },
            { label: '看多機', value: '118', short: '看多機' },
            { label: 'ケアハウス', value: '114', short: 'ケアハ' },
            { label: '介護医療院', value: '120', short: '介護医' },
            { label: '病院', value: '119', short: '病院' },
        ],
    },
    {
        label: '相談支援',
        iconType: 'KoureishaIcon',
        options: [
            { label: '居宅介護支援', value: '122', short: '居支' },
            { label: '地域包括', value: '123', short: '包括' },
            { label: '福祉用具', value: '124', short: '用具' },
        ],
    },
    {
        label: '障害訪問',
        iconType: 'HoumonIcon',
        options: [
            { label: '行動援護', value: '201', short: '行援護' },
            { label: 'ガイドヘルパー', value: '203', short: 'ガイド' },
            { label: '重度障害者等包括支援', value: '202', short: '重支援' },
        ],
    },
    {
        label: '障害通所',
        iconType: 'KurumaisuIcon',
        options: [
            { label: '生活介護', value: '205', short: '生介護' },
            { label: '自立訓練（生活訓練）', value: '211', short: '生訓練' },
            { label: '自立訓練（機能訓練）', value: '210', short: '機訓練' },
            { label: '就労定着支援', value: '219', short: '就定着' },
            { label: '就労A', value: '212', short: '就労A' },
            { label: '就労B', value: '213', short: '就労B' },
            { label: '就労移行', value: '217', short: '就移行' },
            { label: '療養介護', value: '204', short: '療介護' },
            { label: '自立援助', value: '218', short: '自援助' },
        ],
    },
    {
        label: '障害入所',
        iconType: 'KurumaisuIcon',
        options: [
            { label: '障害者施設', value: '207', short: '障害施' },
            { label: '障害GH', value: '208', short: '障GH' },
            { label: '宿泊型自立訓練', value: '209', short: '宿訓練' },
            { label: '障害者ショートステイ', value: '206', short: '障SS' },
        ],
    },
    {
        label: '障害相談支援',
        iconType: 'KurumaisuIcon',
        options: [
            { label: '計画相談支援', value: '214', short: '計相談' },
            {
                label: '地域相談支援（地域移行支援）',
                value: '215',
                short: '移相談',
            },
            {
                label: '地域相談支援（地域定着支援）',
                value: '216',
                short: '定相談',
            },
            { label: '障害児相談支援', value: '226', short: '障相談' },
        ],
    },
    {
        label: '障害児童',
        iconType: 'KurumaisuIcon',
        options: [
            { label: '児童発達支援', value: '220', short: '児発支' },
            { label: '保育所等訪問支援', value: '224', short: '保訪支' },
            { label: '放課後デイ', value: '222', short: '放デイ' },
            { label: '居宅訪問型児童発達支援', value: '223', short: '訪児発' },
            { label: '医療型児童発達支援', value: '221', short: '医児発' },
            { label: '障害児入所', value: '225', short: '児入所' },
        ],
    },
    // { label: 'その他', options: [{ label: 'その他', value: '0' }] },
]
const kangoCommonServiceTypeChoices = [
    {
        label: '病院',
        iconType: 'HospitalIcon',
        options: [
            { label: '病院', value: '304', short: '病院' },
            { label: '介護医療院', value: '120', short: '介護医' },
        ],
    },
    {
        label: 'クリニック',
        iconType: 'HospitalIcon',
        options: [
            { label: 'クリニック', value: '301', short: 'CL' },
            { label: '有床クリニック', value: '303', short: '有床' },
        ],
    },
    {
        label: '訪問',
        iconType: 'HoumonIcon',
        options: [
            { label: '訪問診療', value: '302', short: '訪診療' },
            { label: '訪問看護', value: '104', short: '訪看護' },
            { label: '訪問入浴', value: '103', short: '訪入浴' },
        ],
    },
    {
        label: '介護施設',
        iconType: 'KoureishaIcon',
        options: [
            { label: '老健', value: '109', short: '老健' },
            { label: '特養', value: '115', short: '特養' },
            { label: '介護付き有老', value: '111', short: '有老' },
            { label: 'サ高住', value: '113', short: 'サ高住' },
            { label: 'デイ', value: '106', short: 'デイ' },
            { label: 'その他介護施設', value: '0', short: 'その他' },
        ],
    },
    {
        label: '障害施設',
        iconType: 'KurumaisuIcon',
        options: [
            { label: '重心施設', value: '310', short: '重心' },
            { label: 'その他障害施設', value: '201', short: 'その他' },
        ],
    },
    {
        label: '保育施設',
        iconType: 'HoikuenIcon',
        options: [
            { label: '保育園', value: '401', short: '保育園' },
            { label: '幼稚園', value: '406', short: '幼稚園' },
            { label: 'こども園', value: '408', short: 'こども' },
        ],
    },
    {
        label: 'その他',
        iconType: 'EtcIcon',
        options: [
            { label: '企業系', value: '305', short: '企業系' },
            { label: '看護学校', value: '306', short: '看学校' },
        ],
    },
]
const posCommonServiceTypeChoices = [
    {
        label: '医療機関',
        iconType: 'HospitalIcon',
        options: [
            { label: '病院', value: '304', short: '病院' }, //value:介護の「病院」304
            { label: 'クリニック', value: '301', short: 'CL' },
        ],
    },
    {
        label: '訪問',
        iconType: 'HoumonIcon',
        options: [
            { label: '訪問リハ', value: '105', short: '訪リハ' },
            { label: '訪問看護', value: '104', short: '訪看護' },
            { label: '訪問介護', value: '101', short: '訪介護' },
            //{ label: '訪問マッサージ', value: '???' }, //データ無し
            //{ label: '訪問鍼灸', value: '???' }, //データ無し
        ],
    },
    {
        label: '介護施設等',
        iconType: 'KoureishaIcon',
        options: [
            { label: '老健', value: '109', short: '老健' },
            { label: '特養', value: '115', short: '特養' },
            { label: '有老', value: '111', short: '有老' }, //マッピング有
            { label: 'デイ', value: '106', short: 'デイ' },
            { label: 'デイケア', value: '107', short: 'デイケア' },
            { label: '障害者施設', value: '207', short: '障害施' },
            { label: 'グループホーム', value: '110', short: 'GH' },
            { label: '放課後等デイサービス', value: '222', short: '放デイ' },
            //{ label: '重症児デイサービス', value: '???' }, //データ無し
            { label: '就労継続支援', value: '212', short: '就労A' }, //マッピング有
        ],
    },
    {
        label: '保育施設',
        iconType: 'HoikuenIcon',
        options: [
            { label: '保育園', value: '401', short: '保育園' },
            { label: '幼稚園', value: '406', short: '幼稚園' },
            { label: 'こども園', value: '408', short: 'こども' },
        ],
    },
    {
        label: 'その他',
        iconType: 'EtcIcon',
        options: [
            { label: '健診', value: '311', short: '健診' },
            { label: '地域包括', value: '123', short: '包括' },
            //{ label: '接骨院', value: '???' }, //データ無し
            //{ label: '整骨院', value: '???' }, //データ無し
            //{ label: '自費リハビリ施設', value: '???' }, //データ無し
            //{ label: '一般歯科', value: '???' }, //データ無し
            { label: '企業', value: '305', short: '企業系' }, //value:「企業系」305
            { label: 'その他', value: '0', short: 'その他' },
        ],
    },
]

const eiyoushiCommonServiceTypeChoices = [
    {
        label: '医療機関',
        iconType: 'HospitalIcon',
        options: [
            { label: '病院', value: '304', short: '病院' },
            { label: 'クリニック', value: '301', short: 'CL' },
            { label: '歯科医院', value: '314', short: '歯科医' },
        ],
    },
    {
        label: '保育施設',
        iconType: 'HoikuenIcon',
        options: [
            { label: '保育園', value: '401', short: '保育園' }, //value看護からとってきた(認可保育/保育)
            { label: '幼稚園', value: '406', short: '幼稚園' }, //value看護からとったきた(幼稚園-公立/保育)
            { label: 'こども園', value: '408', short: 'こども' }, //value看護からとってきた(認定こども園－地方裁量型/保育)
        ],
    },
    {
        label: '介護施設',
        iconType: 'KoureishaIcon',
        options: [
            { label: '有料老人ホーム', value: '111', short: '有老' },
            { label: '特別養護老人ホーム', value: '115', short: '特養' },
            { label: '介護老人保健施設', value: '109', short: '老健' },
            { label: 'その他', value: '0', short: 'その他' },
        ],
    },
    {
        label: '飲食店',
        iconType: 'FoodIcon',
        options: [{ label: '飲食店', value: '604', short: '飲食店' }],
    },
    {
        label: 'その他',
        iconType: 'EtcIcon',
        options: [
            //{ label: 'ドラッグストア', value: '313', short: '薬局' },
            //{ label: '配食サービス', value: '601', short: '配食' },
            //{ label: '企業（業務委託）', value: '305', short: '企業' },
            { label: '給食委託会社', value: '605', short: '給食委' },
            { label: 'ホテル関係', value: '606', short: 'ホテル' },
            { label: '企業委託先', value: '607', short: '委託先' },
        ],
    },
]

const dentalCommonServiceTypeChoices = [
    {
        label: '歯科医院',
        iconType: 'HospitalIcon',
        options: [{ label: '歯科医院', value: '314', short: '歯医院' }],
    },
    {
        label: '病院歯科',
        iconType: 'HospitalIcon',
        options: [{ label: '病院歯科', value: '315', short: '病院歯' }],
    },
    {
        label: '訪問歯科',
        iconType: 'HoumonIcon',
        options: [{ label: '訪問歯科', value: '316', short: '訪問歯' }],
    },
    {
        label: '介護施設',
        iconType: 'KoureishaIcon',
        options: [
            { label: '入所', value: '106', short: '入所' }, //value:「デイ」106
            { label: '通所', value: '108', short: '通所' }, //value:「ショートステイ」108
            //介護では入所と通所がグループ名(外側のlabel)として使われていた
        ],
    },
    //プランB(使う予定が無ければdevelopMarge時に削除)
    // {
    //     label: '一般歯科',
    //     iconType: 'HospitalIcon',
    //     options: [
    //         { label: '歯科医院（一般歯科）', value: '314', short: '一般歯' },
    //         { label: '歯科医院（口腔外科）', value: '314', short: '口腔外' },
    //     ],
    // },
    // {
    //     label: '病院歯科',
    //     iconType: 'HospitalIcon',
    //     options: [{ label: '病院歯科', value: '315', short: '病院歯' }],
    // },
    // {
    //     label: '訪問歯科',
    //     iconType: 'HoumonIcon',
    //     options: [{ label: '訪問歯科', value: '316', short: '訪問歯' }],
    // },
    // {
    //     label: '矯正歯科',
    //     iconType: 'HospitalIcon',
    //     options: [
    //         { label: '歯科医院（矯正歯科）', value: '314', short: '矯正' },
    //     ],
    // },
    // {
    //     label: '介護施設',
    //     iconType: 'KoureishaIcon',
    //     options: [
    //         { label: '入所', value: '106', short: '入所' }, //value:「デイ」106
    //         { label: '通所', value: '108', short: '通所' }, //value:「ショートステイ」108
    //         //介護では入所と通所がグループ名(外側のlabel)として使われていた
    //     ],
    // },
]

export const commonServiceTypeChoices = site.isHoiku()
    ? hoikuCommonServiceTypeChoices
    : site.isKango()
    ? kangoCommonServiceTypeChoices
    : site.isKaigo()
    ? kaigoCommonServiceTypeChoices
    : site.isPos()
    ? posCommonServiceTypeChoices
    : site.isEiyoushi()
    ? eiyoushiCommonServiceTypeChoices
    : site.isDental()
    ? dentalCommonServiceTypeChoices
    : null
