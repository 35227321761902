import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { siteName } from '../Layout/HeaderSiteName.js'

// TODO: 内容を決める
const Head = ({ title = '', errorTitle = '', description = '' }) => {
    return (
        <Helmet
            title={
                title
                    ? `${title} | TRYT ${siteName}事業所検索`
                    : errorTitle
                    ? `${errorTitle} | Forward`
                    : undefined
            }
            defaultTitle={`TRYT ${siteName}事業所検索`}
        >
            <meta name="description" content={description} />
        </Helmet>
    )
}

Head.propTypes = {
    title: PropTypes.string,
    errorTitle: PropTypes.string,
    description: PropTypes.string,
}

export { Head }
