import { Heading, Flex, Text, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const FacilityHeading = ({ children, props }) => {
    return (
        <Flex>
            <Stack w="12.5em" direction="row" {...props}>
                <Text border="1.5px solid" bg="tryt.primary-1" color="tryt.primary-1" />
                <Stack />
                <Heading fontSize="20px" fontWeight="bold">
                    {children}
                </Heading>
            </Stack>
        </Flex>
    )
}

FacilityHeading.propTypes = {
    children: PropTypes.string.isRequired,
    props: PropTypes.any,
}
