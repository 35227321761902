import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'
// @ts-ignore
import { HoikuServiceCode, KaigoServiceCode } from 'shared/utils/serviceCode'

import { FacilityContext } from '../index.jsx'

import {
    HoikuFacilityItems,
    KaigoFacilityItems,
    KangoItems,
    KangoOfficeItem,
    FacilityModifiedDateItem,
} from './index.js'

export const KangoFacilityItems = () => {
    const data = useContext(FacilityContext)

    if (KaigoServiceCode(data.serviceCode)) {
        return <KaigoFacilityItems />
    }
    if (HoikuServiceCode(data.serviceCode)) {
        return <HoikuFacilityItems />
    }
    return (
        <Stack spacing="5px">
            <KangoOfficeItem />
            <KangoItems />
            <FacilityModifiedDateItem />
        </Stack>
    )
}
