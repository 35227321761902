import { HStack, Stack, Text, Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { FacilityHeading } from './index.js'

export const TextComponent = ({ label, startStr, endStr, children }) => {
    return (
        children && (
            <Stack>
                {label && (
                    <Text
                        fontSize="md"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        color="tryt.gray-4"
                    >
                        {label}
                    </Text>
                )}
                <Text>
                    {startStr}
                    {children}
                    {endStr}
                </Text>
            </Stack>
        )
    )
}

TextComponent.propTypes = {
    label: PropTypes.string,
    startStr: PropTypes.string,
    endStr: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export const TextHStackComponent = ({ label, startStr, endStr, children }) => {
    return (
        children !== null &&
        children !== undefined &&
        children !== '' && (
            <HStack pr="3rem">
                {label && (
                    <Text
                        fontSize="md"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        color="tryt.gray-4"
                    >
                        {label}
                    </Text>
                )}
                <Text>
                    {startStr}
                    {children}
                    {endStr}
                </Text>
            </HStack>
        )
    )
}

TextHStackComponent.propTypes = {
    label: PropTypes.string,
    startStr: PropTypes.string,
    endStr: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export const InlineLabelComponent = ({ label, startStr, endStr, children }) => {
    return (
        children !== null &&
        children !== undefined &&
        children !== '' && (
            <Box>
                <Text>
                    {label && (
                        <Text
                            as="span"
                            fontSize="md"
                            whiteSpace="nowrap"
                            fontWeight="bold"
                            color="tryt.gray-4"
                            pr="0.5rem"
                        >
                            {label}
                        </Text>
                    )}
                    {startStr}
                    {children}
                    {endStr}
                </Text>
            </Box>
        )
    )
}

InlineLabelComponent.propTypes = {
    label: PropTypes.string,
    startStr: PropTypes.string,
    endStr: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export const TextHeadingComponent = ({ label, children, isObject = [] }) => {
    const isEmpty = useMemo(
        () =>
            isObject.every(
                (item) =>
                    item === null || typeof item === 'undefined' || item === ''
            ),
        [isObject]
    )

    return (
        !isEmpty && (
            <Stack direction="row" pb="20px">
                <FacilityHeading props={{ size: 'md' }}>
                    {label}
                </FacilityHeading>
                {children}
            </Stack>
        )
    )
}

TextHeadingComponent.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
    isObject: PropTypes.array,
}

export const NullableTextHeadingComponent = ({
    label,
    children,
    isObject = [],
}) => {
    const isEmpty = useMemo(
        () =>
            isObject.every(
                (item) =>
                    item === null || typeof item === 'undefined' || item === ''
            ),
        [isObject]
    )

    return (
        !isEmpty && (
            <Stack direction="row" pb="20px">
                <FacilityHeading props={{ size: 'md' }}>
                    {label}
                </FacilityHeading>
                {children}
            </Stack>
        )
    )
}

NullableTextHeadingComponent.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
    isObject: PropTypes.array,
}
