// see: https://v2.chakra-ui.com/docs/styled-system/global-styles
export const globalStyles = () => ({
    'main:has(.fullscreen-view)': {
        minH: 0,
    },
    '.chakra-form-control .chakra-select__wrapper .chakra-select__menu': {
        zIndex: 'dropdown',
    },
    '.gm-style': { fontFamily: 'fonts' },

    '*, *::before, *::after ': {
        // デフォルトのボーダーカラー
        borderColor: 'tryt.gray-3',
    },
})
