import { Box, Heading, HStack, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useState } from 'react'

import {
    StationDetail,
    EkitanStationDetail,
    StationLinkedBy,
    StationLinkage,
} from './StationTableCell'

import { Table, TableContainer, Pagination } from '@/components/Elements'
import { useListRailwayStationsQuery } from '@/features/stations'

const railwayStationColumns = [
    {
        accessor: 'stationBusStopInfo',
        Header: '駅(鉄道)',
        Cell: StationDetail,
    },
    {
        accessor: 'EkitanInfo',
        Header: '駅探',
        Cell: EkitanStationDetail,
    },
    {
        accessor: 'UpdatedInfo',
        Header: '更新情報',
        Cell: StationLinkedBy,
    },
    {
        accessor: 'StationBusStoplinkage',
        Header: '',
        Cell: StationLinkage,
    },
]

const useRailwayStations = () => {
    const initialPage = 1
    const [page, setPage] = useState(initialPage)
    const queryResult = useListRailwayStationsQuery({
        page: page,
        'linkedBy[0]': 'none',
        'linkedBy[1]': 'user',
        limit: 10,
    })
    return { page, setPage, ...queryResult }
}

export const RailwayStationTable = () => {
    const { setPage, data, isSuccess } = useRailwayStations()

    if (!isSuccess) {
        // TODO: ぐるぐる等
        return <div />
    }

    return (
        <RailwayStationTableView
            stations={data?.items ?? []}
            renderPagination={() => (
                <Pagination
                    totalCount={data.paging.pageCount}
                    currentPage={data.paging.page}
                    handlePageChanged={setPage}
                />
            )}
        />
    )
}

RailwayStationTable.propTypes = {}

export const RailwayStationTableView = ({ stations, renderPagination }) => {
    return (
        <Box>
            <Stack
                bg="primary.50"
                maxWidth="1000px"
                w="100%"
                p="35px 25px"
                margin="25px auto 20px auto"
            >
                <HStack>
                    <Heading
                        as="h5"
                        fontSize="20px"
                        color="tryt.primary-1"
                        fontWeight="bolder"
                    >
                        駅（鉄道）紐づけ
                    </Heading>
                </HStack>
                <TableContainer
                    sx={{
                        Thead: {
                            bg: 'tryt.primary-1',
                        },
                        Th: {
                            color: '#FFFFFF',
                            fontSize: '14px',
                            borderColor: 'primary.50',
                            borderRightWidth: '4px',
                            pt: '4px',
                            pb: '4px',
                            minW: '100px',
                            whiteSpace: 'nowrap',
                            fontFamily: 'fonts',
                        },
                        Tbody: {
                            bg: 'white',
                        },
                        Td: {
                            borderColor: 'primary.50',
                            borderRightWidth: '4px',
                            borderTopWidth: '4px',
                            borderBottomWidth: '4px',
                            pt: '4px',
                            pb: '4px',
                            width: '75%',
                        },
                    }}
                >
                    <Table columns={railwayStationColumns} data={stations} />
                </TableContainer>
            </Stack>
            {renderPagination()}
        </Box>
    )
}

RailwayStationTableView.propTypes = {
    stations: PropTypes.array,
    renderPagination: PropTypes.func,
}
