//import { Stack, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useContext } from 'react'

import {
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents/index.js'
import { FacilityContext } from '../index.jsx'

export const EiyoushiItems = () => {
    const data = useContext(FacilityContext)
    return (
        <>
            <TextHeadingComponent label="休業日" isObject={[data.closedDay]}>
                <TextHStackComponent label="">
                    {data.closedDay}
                </TextHStackComponent>
            </TextHeadingComponent>
        </>
    )
}
